import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import axios from 'axios';
import classNames from 'classnames';
import _ from 'lodash';
import empty from 'is-empty';
import '../styles/ConversationMessages.scss';

class ConversationMessages extends React.Component {
  constructor(props) {
    super(props);
    this.getMessages = this.getMessages.bind(this);
    this.markMessageAsRead = this.markMessageAsRead.bind(this);
    this.smsMessagesEventSource = null;
    this.state = {
      messages: []
    };

    window.ReactComponentBridge__ConversationMessages = this;
  }

  _get() {
    return this.state.messages;
  }

  getMessages() {
    if (!empty(this.props.conversationId)) {
      const endpoint = `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/conversation/${this.props.conversationId}/messages`;
      this.smsMessagesEventSource = new EventSource(endpoint, { withCredentials: true });

      this.smsMessagesEventSource.addEventListener('conversationMessages', (event) => {
        try {
          const data = JSON.parse(event.data);
          let messages = _.get(data, "messages");
          messages = _.concat(this.state.messages, messages);
          messages = _.uniqBy(messages, "sid");
          messages = _.sortBy(messages, (message) => {
            return message.dtc;
          }).reverse();

          // Mark unread messages as read
          for (let message of messages) {
            if (message.status === "unread") {
              this.props.setConversationAsRead(message.conversation_id);
              this.markMessageAsRead(message.sid);
            }
          }

          this.setState({ messages });

        } catch (e) {}
      });
    }
  }

  /**
   * Marks a message as read
   * @param {string} messageSid
   *  - A Twilio-provided string that uniquely identifies the Message resource to update.
   */
  async markMessageAsRead(messageSid) {
    const response = await axios
      .post(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/mark-as-read`, {
        message_sid: messageSid
      }, {
        withCredentials: true
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversationId != this.props.conversationId) {
      if (!empty(this.smsMessagesEventSource)) {
        // Disconnect from EventSource
        this.smsMessagesEventSource.close();
        this.smsMessagesEventSource = null;
        this.setState({ messages: [] });
      }

      this.getMessages();

    }
  }

  render() {
    return (
      <div className="conversation-messages">
        {this.state.messages.map((msg) => {
          return (
            <Message
              key={"message-" + msg.sid}
              className={classNames({
                inbound: msg.direction === "inbound",
                outbound: msg.direction === "outbound"
              })}
            >
              <p>{msg.body}</p>
              <p className="msg-time">{msg.dtc}</p>
            </Message>
          )
        })}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConversationAsRead: (conversationId) => dispatch({
      type: "SET_CONVERSATION_AS_READ",
      payload: conversationId
    })
  }
}

export default connect(null, mapDispatchToProps)(ConversationMessages);
